<template>
  <div class="login-container">
    <div class="container-login100 bg-slide">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-login100">
        <div class="login100-pic" data-tilt>
          <img :src="'/img/theme/card-p2.jpeg'" alt="IMG" />
        </div>

        <el-form :model="passwordForm" status-icon :rules="passwordRules" ref="passwordForm">
              <span class="login100-form-title"> Choose a new password </span>
              <el-form-item prop="password" label="New password">
                <el-input type="password" v-model="passwordForm.password" placeholder="New password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item prop="confirm_password" label="Repeat your new password">
                <el-input type="password" v-model="passwordForm.confirm_password" placeholder="Repeat your password" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item>
                <div class="container-login100-form-btn">
                  <el-button :loading="loading" :disabled="loading" class="login100-form-btn" type="primary" @click="SendNewPassword">Change password</el-button>
                </div>

              </el-form-item>
            </el-form>

      </div>
    </div>
  </div>
</template>

<script>

import {resetForgetPassword} from '@/api/user'
export default {
  data(){
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password'));
        } else {
          if (this.passwordForm.confirm_password !== '') {
            this.$refs.passwordForm.validateField('confirm_password');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the password again'));
        } else if (value !== this.passwordForm.password) {
          callback(new Error('Two inputs don\'t match!'));
        } else {
          callback();
        }
      };
    return{
      loading: false,
      passwordForm: {
        password: '',
        confirm_password: ''
      },
      passwordRules: {
        
        password: [{ validator: validatePass, trigger: "blur" }],
        confirm_password: [{ validator: validatePass2, trigger: "blur" }],
       
      },
    }
  },
  methods: {
    SendNewPassword(){
      this.$refs.passwordForm.validate((valid) => {
        if(valid){
          this.loading = true
          resetForgetPassword(this.$route.params.token, this.passwordForm).then(({result, message}) => {
            this.loading = false        
            if(result){
              this.$swal(`Message`, message, 'success').then(()=>{
                this.$router.push('/login')
              })
              
            }else{
              this.loading = false
              this.$swal('Message', message, 'error')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/login.scss";
.new-password-container{
  .el-form-item__content {
    line-height: 30px;
    position: relative;
    font-size: 14px;
  }
  .el-form-item {
      margin-bottom: 16px;
  }
}
</style>